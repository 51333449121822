<template>
  <div class="brandBoxs">
    <mobileHeader />
    <div class="brandimg">
      <img src="../../assets/m_brandbg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.brandCenter.bannerTitle") }}
      </div>
    </div>
    <div class="brandTitle">
      <div class="titles">{{ $t("lang.brandCenter.titles") }}</div>
      <div class="imgs">
        <img src="../../assets/m_brandlogo.jpg" />
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "brandCenter",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.brandBoxs {
  .brandimg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #006ebb;
      text-shadow: 0 0 25px #6d8fb7;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .brandTitle {
    padding: 0 25px;
    margin: 0 auto;
    margin-bottom: 40px;
    .titles {
      font-size: 20px;
      font-weight: bold;
      margin: 50px 0;
    }
    .imgs {
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
  }
}
</style>
